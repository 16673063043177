
/* To prevent Navbar from changing positions when page does not have scroll bar. */
html
{
  overflow-y: scroll;
  background: #f3f3f3;
}
.nav {
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 110px;
  background: white;
  color: black;
  box-shadow: 0px 8px 8px -6px rgba(0, 0, 0, 0.5);
  margin-bottom: 0;
  width: 100%;
}

footer {
  display: flex;
  justify-content: space-around;
  align-items: center;
  min-height: 10vh;
  background: black;
  color: white;
}

h1 {
  font-family: 'Scheherazade', serif;
  font-weight: bold;
  font-size: 4.3rem;
  color: #424242;
  line-height: 4.5rem;
}

h2 {
  font-family: 'Scheherazade', serif;

  line-height: 3.75rem;
  font-size: 3.4375rem;
  color: #424242;
}

h3 {
  font-family: 'Scheherazade', serif;
  font-size: 2rem;
  line-height: 3.75rem;
  color: #424242;
}

p
{
  font-family: 'Oswald', sans-serif;
  font-size: 1rem;
  line-height: 2rem;
  letter-spacing:1px;
}

button {
  background-color: #009A7B !important;
  color: white !important;
  font-size: 1rem !important;
  padding: 0.4rem 1rem !important;
  border: none !important;
}

button:hover {
  background-color: #faa919 !important;
}

.logo {
  height: 100px;
  width: auto;
  padding: 1rem;
}

p {
  margin: auto;
  margin-bottom: 18px;
  font-weight: 10;
  font-size: 12pt;
  color: #ffb83c;
}

ul {
  list-style: none;
  text-align: left;
  padding: 0;
}

.top {
  background-color: #009A7B;
  height: 70px;
}

.nav-links {
  width: 35%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1.1rem;
  font-weight: 350;
}

.link {
  color: white;
  text-decoration: none;
}

.nav-link {
  text-decoration: none;
  color: black;
}

.nav-link:hover {
  color: #faa919;
}

.home p {
  color: black;
  line-height: 25pt;
  margin-bottom: 8%;
}

/* .home h1 {
  color: black;
  font-size: 4.5;
  line-height: 60pt;
  letter-spacing: 1.8pt;
  margin-bottom: 7%;
  margin-top: 6%;
  margin-right: 0.5%;
} */

.sub-heading  {
  color: #faa919;
  font-size:1.3rem;
  line-height: 33px;
  margin-bottom: 0;
}

.home-top {
  display: flex;
  justify-content: space-between;
  margin-top: 5%;
  margin-left: 10%;
  margin-right: 10%;
  padding-bottom: 2%;
}

.home-top > div {
  width: 50%;
}

.home-top > img {
  width: 35%;
  margin-left: auto;
  margin-bottom: auto;
}

.home-top button {
  margin-top: 4%;
}

.home-container {
  background-image: linear-gradient( rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3) ), url("./images/newpohextended.jpg");
  background-size: cover;
  min-height: 700px;
  background-position: center 42px;
}

@media screen and (max-width: 600px) {
  .home-svg {
    transform: translate(0px, -1px);
  }
  .home-container {
    background-position: center -10px;
    min-height: 550px;
  }
}

@media screen and (max-width: 900px) {
  .home-container {
    background-position: center 20px;
  }
}

/* .home-container .shadow-test
{

    filter: drop-shadow( 15px 10px 2px rgba(0, 0, 0, 0.2));

} */

.home-swiper {
   background-color: #f3f3f3;
   display: flex;
   align-items: center;
   justify-content: center;
   padding-top: 3%;
   padding-bottom: 2%;
}

.home-swiper .learn-more {
  margin-right: auto;
  margin-bottom: 10%;
}

.learn-more svg {
  font-size: 4.2rem;
  margin-top: 1%;
  color: black;
}

.learn-more svg:hover {
  color: gray;
}

.learn-more img {
  height: auto;
  width: 90px;
  margin-bottom: 3%;
}

.home-swiper .swiper-slide {
  display: flex;
  justify-content: center;
  align-items: center;
}

.donate-btn {
  text-transform: uppercase;
  margin-top: 3%;
  margin-bottom: 1%;
}

.section {
  padding: 120px;
  display: flex;
  text-align: left;
  flex-wrap: wrap;
}

.info {
  flex: 1;
  margin: auto;
  padding: 50px;
  padding-top: 0;
}

.image {
  object-fit: cover;
  max-width: 50%;
  margin: auto;
}

.reverse {
  flex-direction: row-reverse;
}

@media (max-width: 667px) {
  .image {
    max-width: 100%;
    margin: 0;
  }


}

.column {
  margin-bottom: 2%;
}

.column-img,
.column-h,
.column-p {
  width: 100%;
  text-align: left;
}

.column-h {
  margin-top: 4%;
}

.donate-img {
  width: 200px;
}

.services-main {
  width: 80%;
  height: auto;
  margin-bottom: 5%;
}

.services-img {
  object-fit: contain;
}

.home-page-top {
  margin-left: 12%;
  margin-right: 12%;
}

.line-header {
  display: flex;
  width: 100%;
  justify-content: space-evenly;
  align-items: center;
  text-align: center;
  margin-top: 4%;
}

.line-header:before,
.line-header:after {
  content: "";
  border-top: 2px solid;
  flex: 0.26 0 20px;
}

.instagram {
  width: 100px;
}

.facebook {
  padding: 30px;
  width: 105px;
}

.icon {
  padding: 20px;
  width: 90px;
}

.social-media-icons {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.flyer {
  background-color: rgb(255, 236, 208);
}

.welcome {
  background-color: #faa919;
  padding: 2%;
  padding-left: 5%;
  padding-right: 5%;
  margin-right: 45%;
  text-align: left;
}

.welcome > h3 {
  color: #009A7B;
  font-weight: 500;
  margin-bottom: 1%;
}

.welcome > p {
  color: rgb(255, 236, 208);
  padding-right: 12%;
}

.home-data {
  background-color: rgb(255, 236, 208);
  display: flex;
  padding-left: 4%;
  padding-right: 4%;
}

/* NEWS AND EVENTS */

.news-events-image {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  background-image: linear-gradient( rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3) ), url("https://images.unsplash.com/photo-1564189365442-987dc2d76f7e?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1489&q=80");
  background-position: center;
  background-size: cover;
  height: 500px;
  background-repeat: no-repeat;
}

.news-events-image h1, .news-events-image h3 {
  color: white;
}

.calendar-container, .media-container {
  padding: 5%;
  padding-left: 10%;
  padding-right: 10%;
}

.media-container {
  background-color: #1d1d1d;
}

.media-container h2, .media-container p {
  color: white;
}

.media-container .swiper-slide {
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0.4;
}

.media-container #gallery {
  height: 550px;
}

.media-container #thumbs {
  height: 150px;
}

.media-container .swiper-slide-active {
  opacity: 1.0;
}

.media-container hr {
  border: none;
  height: 1px;
  background-color: white;
  margin-top: 3%;
  margin-bottom: 3%;
}

.large-video {
  width: 100%;
}

.small-videos {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.small-videos video, .small-videos iframe {
  height: 300px;
  margin-top: 3%;
  margin-bottom: 3%;
  margin-left: 2%;
  margin-right: 2%;
}

.fb-video {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.fb-video iframe {
  border:none;
  overflow:hidden;
}

@media screen and (max-width: 400px) {
  .small-videos video, .small-videos iframe {
    height: auto;
    width: 99%;
  }

  .calendar-container {
    padding: 1% !important;
  }

  .calendar-container > h2 {
    text-align: center;
  }

  .rbc-btn-group > button {
    font-size: 12pt !important;
  }
}

.donate {
  text-align: left;
  line-height: 25pt;
}

.donate p {
  color: black;
}

.donate h3 {
  color: #faa919;
}

.donate h3, .donate h4 {
  line-height: 22pt;
}

.donations-container {
  display: inline-flex;
  margin: 1.5%;
  gap: 15%;
}

.donations-container > * {
  flex: 1;
}

.donate-row, .donate-header, .appr-of-funding > *,
.services-main {
  margin-left: 10% !important;
  margin-right: 10% !important;
}

.column-container {
  margin-left: 8% !important;
  margin-right: 8% !important;
}

.donate-header > h2 {
  margin-top: 4%;
}

.donate-header h2 {
  color: #009A7B;
}

.donate-header h3 {
  font-weight: 700;
  color: #424242;
}

.donate-row > * > h3 {
  color: #009A7B;
}

.donate-affiliate {
  margin-bottom: 6% !important;
}

hr {
  border: none;
  height: 1px;
  background-color: black;
}

.donate-links {
  display: flex;
  flex-wrap: wrap;
}

.donate-links img {
  height: 30px;
}

.donate-links p {
  margin-left: 8%;
}

.donate-links a, .donate-links span {
  display: flex;
  margin-right: 5%;
}

.affiliate-container {
  display: flex;
  margin-bottom: 1%;
}

.affiliate-img {
  width: 100%;
  margin-top: 10%;
  margin-left: auto;
  margin-bottom: auto;
}

.affiliate-container > div > div {
  margin-bottom: 4%;
}

.appr-of-funding {
  margin: 0;
  background-color: #e9e9e9;
}

.appr-of-funding h2 {
  padding-top: 5%;
}

.funding-container, .column-container {
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 1%;
}

.funding-container {
  justify-content: space-between;
}

.column-container {
  justify-content: space-around;
}

.funding-container h4 {
  font-size: 16pt;
}

.funding-container > * {
  margin-bottom: 2%;
  margin-top: 2%;
}

@media screen and (min-width: 900px) {
  .funding-container > *, .column-container > * {
    max-width: 29%;
  }
}

@media screen and (max-width: 900px) {
  .funding-container > *, .column-container > * {
    max-width: 46%;
  }
}

@media screen and (max-width: 600px) {
  .funding-container > *, .column-container > * {
    max-width: 99%;
  }
}

.btm-pd {
  padding-bottom: 5%;
}

.col-md, .col-md-6, .col-lg-4 {
  padding: 0;
}

.slider-img {
  list-style: none;
  max-height: 100%;
  max-width: 90%;
}

.swiper-container {
  height: 700px;
  margin-bottom: 2%;
}

@media screen and (min-width: 800px) {
  .swiper-container {
    max-width: 460px;
  }
}

.swiper-button-prev {
  color: #009A7B !important;
}

.swiper-button-next {
  color: #009A7B !important;
}

.swiper-pagination-bullet-active {
  background-color: #009A7B !important;
}

.swiper-pagination {
  position: absolute;
  bottom: 30px !important;
}




/* Row -bootsrap remove Margin */

.row
{
  margin: 0;
}

.home-section
{
  max-width: 1334px;
  margin: 0 auto;
  padding: 2rem 0;
}

.home-col-title
{
  text-align: center;
  margin-top:3rem;

}
@media screen and (max-width: 700px)
{
  .home-col-title
  {
    padding: 1rem;
  }

  .home-top {
    flex-direction: column-reverse;
  }

  .home-top > * {
    width: 100% !important;
  }

  .home-top > img {
    width: 80% !important;
    height: 80% !important;
    margin-right: auto;
    margin-bottom: 5%;
  }
}
.home-col-title h1, p
{
  padding: 0 !important;
  margin: 0 !important;
}

.home-col-title h1
{
  font-family: 'Scheherazade', serif;
  font-weight: bold;
  font-size: 4.3rem;
  color: #424242;
  line-height: 4.5rem;
  letter-spacing: normal;
}

.home-col-title p
{
  line-break: normal;
  margin: 0 auto;
}

/* Featured Events */
.featuredEventsContainer
{
  max-width: 100%;
  background-color: #F3F3F3;
  height: auto;
  padding: 2rem 0;
}
.featuredEvents
{

  height: auto;
  max-width: 1440px;
  margin: 0 auto;

}



/* Responsive */
@media  screen and (max-width: 900px)
{
  .upcomingEvents
  {
    justify-content: center;
  }

  .featuredEvents h4
  {
    text-align: center;
    padding: 2rem 0;

  }

  .upcomingEvents img
  {
    margin-bottom: 1rem;
  }

  .upcomingEventsImage {
    margin-bottom: 5rem !important;
  }

}

.upcomingEvents img
{
  height: 100%;
  width: 100%;
  padding: 0;
  margin: 0;
}

.upcomingEventsImage {
  margin-bottom: auto;
}

/* @media screen and (max-width: 800px)
{
  .upcomingEvents img
  {
    width: 100%;
    height: 100%;
    margin: 0 auto;
  }
} */

.featuredEvents h4
{
  font-size: 1.5rem;
  font-weight: 500;
  line-height: 42px;
  padding-left: 1rem;
}

/* Upcming Events List */
.upcomingEventsList
{
  display: flex;
  flex-direction: column;
  /* padding-left: 2rem; */
}
.upcomingEventsList h4
{
  padding-left: 1rem;
  font-weight: 500;
  font-size: 1.3rem;
}

.upcomingEvents
{
  border-right: 1px solid black;
}

.upcomingTitle 
{
  overflow: hidden;
  white-space: nowrap;
}

@media screen and (max-width: 900px)
{
  .upcomingEvents
  {
    border-right: none;
  }
  .pastEventsCol
  {
    margin-top: 1rem;
    padding: 1.2rem;
  }
}

/* Past EVents */

.pastEventsCol
{
  height: 100%;
}
/* .pastEventsCol h4
{
  padding-left: 1rem;
  font-weight: 500;
  font-size: 1.3rem;
} */
/* ----------------------------------------------------------------------- */
.contact-top {
  background: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url('./images/holding-hand.jpg');
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  background-position: center;
  background-size: cover;
  height: 500px;
}

@media screen and (min-width: 1400px) {
  .contact-top {
    background-position: center -270px;
  }
}

.contact-top h1, .contact-top h3 {
  color: white;
}

.contact-container {
  display: flex;
  margin: 6%;
  margin-left: 8%;
  margin-right: 4%;
  justify-content: space-between;
}

@media screen and (max-width: 620px) {
  .contact-container {
    flex-direction: column;
  }

  .contact-container > * {
    width: 100% !important;
  }

  .contact-container h2 {
    line-height: 3rem;
    margin-top: 30px !important;
  }

  .contact-top h3, .news-events-image h1, .news-events-image h3 {
    margin-left: 10%;
    margin-right: 10%;
    text-align: center;
  }

  .contact-top h3, .news-events-image h3 {
    line-height: 2.4rem;
  }
}

.contact-container h3 {
  margin-top: 8%;
}

.contact-container p {
  color: black;
}

.get-in-touch {
  display: flex;
  flex-direction: column;
  width: 50%;
}

.contact-form {
  display: flex;
  flex-direction: column;
  margin-top: 2%;
}

.contact-form input, .contact-form textarea {
  width: 100%;
  padding: 16px;
  margin-top: 4%;
  border: 1px solid gray;
  border-radius: 6px;
}

.contact-form input::placeholder, .contact-form textarea::placeholder {
  color: lightgray;
  letter-spacing: 3px;
}

.contact-form textarea {
  height: 150px;
  margin-bottom: 2%;
}

.contact-info {
  width: 32%;
}

.contact-info span {
  color: #faa919;
}

.contact-info h2 {
  margin-top: 5%;
  margin-bottom: 0;
}

.contact-submit {
  width: 200px !important;
  letter-spacing: 3px;
  font-size: 14pt;
  color: white;
  background-color: #009A7B;
  border: none !important;
  border-radius: 0 !important;
}

.rbc-toolbar-label {
  font-size: 26pt;
  font-weight: 300;
}

.rbc-header {
  color: white;
  padding: 1% !important;
  border: black !important;
}

div.rbc-row.rbc-month-header {
  background: #1d1d1d;
}

.rbc-toolbar button {
  background-color: white !important;
  color: black !important;
  border: 1px solid #ccc !important;
}

.modal-header button {
  background: transparent !important;
}
.modal-header button > span {
  color: black !important;
}

/* GET HELP Page */

.GetHelp {
  background-color: #f3f3f3;
  padding-bottom: 5%;
}

.get-help-top {
  background-image: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url("./images/hands-in-heart.jpg");
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  background-position: center;
  background-size: cover;
  height: 500px;
}

@media screen and (max-width: 700px) {
  .get-help-top > * {
    margin-left: 10%;
    margin-right: 10%;
    text-align: center;
  }

  .get-help-top h3 {
    line-height: 2.4rem !important;
  }
}

.get-help-top h1, .get-help-top h3 {
  color: white;
}

.get-help-container {
  display: flex;
  justify-content: space-around;
  margin: 6%;
  margin-left: 8%;
  margin-right: 8%;
}

@media screen and (max-width: 900px) {
  .get-help-container {
    flex-direction: column;
    margin-left: 14%;
    margin-right: 14%;
  }

  .get-help-card:first-child {
    margin-bottom: 8% !important;
  }

  .get-help-container img {
    width: 100% !important;
  }
}

@media screen and (max-width: 380px) {
  .get-help-container, .outreach-container {
    margin: 3% !important;
  }

  .get-help-card {
    margin-bottom: 8% !important;
  }
}

.get-help-container img {
  max-width: 360px;
}

.get-help-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2%;
  padding-left: 4%;
  padding-right: 4%;
  border: solid gray;
  border-radius: 15px;
  background-color: white;
}

.get-help-card h2, .get-help-card p {
  color: black;
  margin-top: 6%;
  line-height: 2rem;
}

.get-help-card h2 {
  font-size: 26pt;
}

.get-help-card p {
  font-size: 12pt;
  max-width: fit-content;
  text-align: center;
}

.get-help-card button {
  padding-left: 10%;
  padding-right: 10%;
  font-size: 14pt;
  color: white;
  background-color: #009A7B;
  border: none;
  margin-top: 6%;
}

.get-help-card a {
  margin-top: 3.5%;
  width: 40%;
}

.get-help-services-btn {
  display: flex;
  justify-content: center;
  align-items: center;
}

.get-help-services-btn:hover {
  text-decoration: none;
}

.outreach-container {
  margin-left: 14%;
  margin-right: 14%;
  display: flex;
  flex-direction: column;
  text-align: center;
  border: solid gray;
  border-radius: 10px;
  padding-left: 8%;
  padding-right: 8%;
  padding-top: 3%;
  padding-bottom: 3%;
  background-color: white;
}

.outreach-container h2 {
  color: black;
  font-size: 34pt;
}

.outreach-container p {
  color: black;
  font-size: 17pt;
  margin: 1% !important;
}

.outreach-form {
  display: flex;
  flex-direction: column;
  text-align: left;
}

.outreach-form p {
  margin-left: 0 !important;
  margin-top: 3% !important;
}

.checkbox {
  display: flex;
  justify-content: flex-start;
}

.outreach-form input[type='checkbox'] {
  width: 40px;
  margin-top: auto;
  margin-bottom: auto;
}

.outreach-form input, .outreach-form textarea, .outreach-form select {
  width: 100%;
  padding: 16px;
  margin-top: 4%;
  border: 1px solid gray;
  border-radius: 6px;
}

.outreach-form input::placeholder, .outreach-form textarea::placeholder {
  color: lightgray;
  letter-spacing: 3px;
}

.outreach-form textarea {
  height: 150px;
}

.outreach-submit {
  width: 200px !important;
  letter-spacing: 3px;
  font-size: 14pt;
  color: white;
  background-color: #009A7B;
  border: none !important;
  border-radius: 0 !important;
}

/* ABOUT Page */

.about-container
{
  position: relative;
  margin: 0;
  padding: 0;
}




.home-video
{
  position: relative;
  padding: 0;
  margin: 0;
  display: grid;
  place-items: center;
}

 .home-overlay
 {
   width: 100%;
   height: 100%;
   position: absolute;
   z-index: 1;
   top: 0;
   background-color: #000000ab;
   display: grid;
   place-items: center;
 }
 .home-overlay h1
 {
   z-index: 99;
   color: white;
   font-weight: 700;
   font-size: 6rem !important;
   cursor: pointer;
 }

 .home-overlay p,  .home-overlay a
 {
   color: #ccc;
   cursor: pointer;
 }

 @media screen and (max-width: 900px)
 {

   .home-overlay h1
   {
     font-size: 3rem !important;
   }
   .home-text
   {
     padding: 1rem !important;
   }
 }
 @media screen and (max-width: 432px)
{

  .home-overlay h1
  {
    line-height: 2.5rem !important;
  }
  .home-overlay p
  {
    line-height: 1rem !important;
  }
}

.partners-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 6% 20% 6% 20%;
}

.partners-container img {
  min-height: 100px;
  height: 200px;
}